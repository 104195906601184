import React, { useContext } from 'react';
// Libraries
import { LogoutOutlined } from '@ant-design/icons';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { AuthContext } from '~/contexts/AuthContext';
import { LanguageContext } from '~/contexts/LanguageContext';
//Components
import Button from '~/components/button';
// Standalone Components
import { Container } from './style';
// Environments
import System from '~/environments/System';
// Utils
import { GetModuleNameByHostname } from '~/utils/Hosts';

export default function LogoutButton() {
  const { Logout } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const { translate } = useContext(LanguageContext);
  return (
    <Container>
      <Button
        icon={<LogoutOutlined />}
        onClick={() => Logout(GetModuleNameByHostname() === 'localhost' ? 'http://localhost:3000' : `https://${System.hostname.operationsFinder}`)}
        type="link"
        size="large"
        style={{ color: theme.color.danger }}
      >
        {translate.basic.logout.text}
      </Button>
    </Container>
  );
}