import React from 'react';
// Components
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Divider from '~/components/divider';

export default function Terms() {
  return (
    <div>
      <Divider />
      <Title level={4}>
        POLÍTICA DE COOKIES E FERRAMENTAS DE COLETA AUTOMÁTICA DE DADOS
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Com vistas à observância do princípio da transparência, bem como
        prezando pelo bom relacionamento com o USUÁRIO, a SOCIALME deixa sempre
        claro ao USUÁRIO o modo de atuação de sua PLATAFORMA.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Dessa maneira, o USUÁRIO, quando do aceite dos Termos de Uso e Política
        de Privacidade, deverá, ainda, tomar conhecimento e declarar seu
        CONSENTIMENTO a esta Política de Cookies e ferramentas de coleta
        automática de dados, que visa esclarecer como tais ferramentas
        funcionam, seu propósito, bem como as formas de ativação e desativação.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Assim, é fundamental a leitura atenta desta Política de Cookies e
        ferramentas de coleta automática de dados pelo USUÁRIO, antes de se
        cadastrar ou mesmo navegar na PLATAFORMA do SOCIALME.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        É muito IMPORTANTE o conhecimento do USUÁRIO, no sentido de que, apesar
        de ser uma opção sua de permitir ou não o uso de cookies e outras
        ferramentas de coleta de dados, a negativa do USUÁRIO quanto à aplicação
        de tais ferramentas INVIABILIZARÁ A UTILIZAÇÃO DA PLATAFORMA DE MANEIRA
        INTEGRAL E APROPRIADA.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Para fins de esclarecimentos acerca da presente política de cookies, o
        usuário poderá entrar em contato com a SOCIALME, por intermédio de seu
        encarregado, pelo e-mail: suporte@socialme.com.br
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        1. O QUE SÃO COOKIES E FERRAMENTAS DE COLETA DE DADOS
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Cookies são um pequeno arquivo de dados, com uma etiqueta de
        identificação exclusiva, que os websites solicitam que o navegador dos
        usuários armazene, seja no computador ou quaisquer dispositivos móveis.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        O principal objetivo dos cookies é permitir que o navegador do USUÁRIO
        registre suas ações e/ou preferências, para maior agilidade, eficiência
        e customização, eliminando a necessidade de introduzir repetidamente as
        mesmas informações.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Assim, os cookies são usados, dentre outros fins, para identificar os
        USUÁRIOS, armazenar e lembrar suas preferências personalizadas,
        auxiliá-los a completar tarefas sem ter que reinserir informações quando
        da navegação de um site para outro, ou por ocasião de nova visita à
        PLATAFORMA.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        São utilizados pela PLATAFORMA cookies analíticos e de rastreamento,
        objetivando coletar dados do usuário e do seu comportamento, para
        viabilização do funcionamento da PLATAFORMA.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Importante salientar que são coletadas somente as informações
        estritamente necessárias ao integral e apropriado funcionamento da
        PLATAFORMA, bem como à prestação de serviços descritos nos termos de
        uso, de acordo com princípio da necessidade, inciso III do artigo 6º da
        Lei 13.709/2018 (“LGPD”).
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Esta Política de Cookies está em consonância com a Política de
        Privacidade e Termos de Uso da SOCIALME, sendo de essencial importância
        que o USUÁRIO leia, compreenda integralmente e concorde com os termos
        desses documentos, para fins de uso da PLATAFORMA.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        2. QUE TIPO DE COOKIES UTILIZAMOS?
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        COOKIES PERMANENTES: são cookies que ficam armazenados no navegador e
        que são utilizados sempre que o USUÁRIO faz uma nova visita à nossa
        PLATAFORMA. São utilizados, geralmente, para direcionar a navegação aos
        interesses do USUÁRIO, com vistas a um serviço personalizado.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        COOKIES DE SESSÃO: são cookies temporários que permanecem no arquivo de
        cookies do navegador do USUÁRIO, até sua saída do website. A informação
        obtida por estes cookies serve para analisar padrões de tráfego de
        navegação, permitindo-nos identificar problemas e possibilitar uma
        melhor experiência.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        3. CATEGORIAS DE COOKIES
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        PREFERÊNCIAS: Permite que a PLATAFORMA mantenha informações que alteram
        o modo de comportamento ou exibição do site, incluindo idioma, região e
        outros.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        SEGURANÇA: Objetiva verificar a identidade do usuário, buscando evitar a
        utilização fraudulenta de credenciais de login e proteger os dados do
        usuário de terceiros não autorizados.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        ANALÍTICOS: Permite que proprietários de sites e aplicativos entendam e
        analisem como os visitantes utilizam e interagem com seus websites,
        produtos e serviços, por meio da coleta de informações e estatísticas,
        bem como de uso de relatórios de website.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        RASTREAMENTO: Viabiliza o armazenamento de arquivos nos dispositivos dos
        visitantes dos websites dos USUÁRIOS, registra cliques em links,
        anúncios e transações comerciais, como vendas e leads (informação sobre
        o interesse do USUÁRIO por um produto ou serviço).
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Ressalte-se, ainda, a obrigação dos PARCEIROS da SOCIALME, quanto à
        obtenção do consentimento livre, expresso e inquestionável dos seus
        usuários e visitantes dos websites e demais ambientes em que os links da
        PLATAFORMA são compartilhados e/ou disponibilizados.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Ademais, os PARCEIROS da SOCIALME são integralmente responsáveis por
        cumprir as leis e normativos aplicáveis relativos a proteção de dados
        pessoais, em especial a Lei 13.709/2018, Lei Geral de Proteção de Danos
        (“LGPD”), assumindo a obrigação de obter o consentimento expresso e
        inequívoco de seus usuários/clientes relativamente a coleta,
        armazenamento, tratamento e processamento de dados pessoais, nos termos
        da legislação aplicável. Assim, o SOCIALME não será responsabilizada por
        eventual falha de seus PARCEIROS quanto ao cumprimento das normas
        vigentes, se reservando ao direito de suspender e/ou cancelar o contrato
        com PARCEIROS que não estejam em conformidade com a legislação de
        proteção de dados aplicável.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        PUBLICIDADE: Utilizada para direcionar a publicidade aos interesses do
        USUÁRIO, melhorando sua experiência e buscando conferir maior
        efetividade para anunciantes.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        PROCESSOS: Objetiva melhorar o funcionamento da PLATAFORMA, buscando que
        os USUÁRIOS encontrem os serviços e informações esperados.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        4. É POSSÍVEL AO USUÁRIO GERENCIAR OS COOKIES?
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        SIM. Ainda que a maioria dos navegadores tenha como configuração padrão
        o aceite automático de cookies, o USUÁRIO poderá alterar essa
        configuração, por meio do acesso às configurações do próprio navegador,
        com vistas a bloquear o uso de cookies ou, ainda, para que seja alertado
        sobre o envio de cookies ao seu dispositivo.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Ressalte-se, entretanto, que, ao desabilitar, parcialmente ou totalmente
        os cookies, o SOCIALME poderá ficar impossibilitado de prestar os
        serviços ofertados por meio da PLATAFORMA, ou poderá resultar no
        funcionamento parcial e inadequado do seu website.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        5. ALTERAÇÕES DESTA POLÍTICA DE COOKIES
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        O SOCIALME se reserva o direito de, a qualquer momento, alterar esta
        Política de Cookies, para fins de atualização de seu conteúdo e
        atendimento às necessidades de aperfeiçoamento tecnológico ou de suas
        funcionalidades.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Por ocasião de eventual alteração desta Política de Cookies, o SOCIALME
        comunicará o USUÁRIO por meio de publicação em área de destaque na
        PLATAFORMA, bem como solicitará a manifestação expressa de sua
        concordância com as alterações efetuadas.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        A permanência do USUÁRIO na PLATAFORMA, com a utilização das ferramentas
        oferecidas, será considerada como CONSENTIMENTO do USUÁRIO.
      </Text>
      <br />
    </div>
  );
}
