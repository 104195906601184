import React, { useContext, memo } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { ThemeContext } from '~/contexts/ThemeContext';
import { LanguageContext } from '~/contexts/LanguageContext';
// Libraries
import IconHelper from '~/components/iconHelper';
// Standalone Components
import {
  TextInput,
  PasswordInput,
  MaskedInput,
  Container,
  LabelContainer,
  RequiredSpan,
} from './style';

function AntInput({
  mask,
  disabled,
  placeholder,
  size,
  type,
  prefix,
  width,
  margin,
  value,
  onChange,
  onFocus,
  onBlur,
  helpText,
  maxLength,
  label,
  outFormItem,
  suffix,
  required,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);
  const { translate } = useContext(LanguageContext);

  const RenderMaskedInput = () => {
    return (
      <MaskedInput
        disabled={!!disabled}
        mask={mask}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        size={isMobile ? 'large' : size ? size : 'default'}
        placeholder={placeholder}
        type={type || 'text'}
        prefix={prefix || false}
        margin={margin}
        maxLength={maxLength}
        width={width}
        color={theme.color.textOnSecondaryBackground}
        backgroundcolor={theme.color.backgroundSecondary}
      />
    );
  };

  const RenderPasswordInput = () => {
    return (
      <PasswordInput
        disabled={!!disabled}
        size={isMobile ? 'large' : size ? size : 'default'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        type={'password'}
        prefix={prefix || false}
        margin={margin}
        maxLength={maxLength}
        width={width}
        color={theme.color.textOnSecondaryBackground}
        backgroundcolor={theme.color.backgroundSecondary}
        autoComplete="password"
      />
    );
  };

  const RenderDefaultInput = () => {
    return (
      <TextInput
        disabled={!!disabled}
        size={isMobile ? 'large' : size ? size : 'default'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        type={type || 'text'}
        prefix={prefix || false}
        suffix={helpText ? <IconHelper text={helpText} /> : suffix}
        margin={margin}
        maxLength={maxLength}
        width={width}
        color={theme.color.textOnSecondaryBackground}
        backgroundcolor={theme.color.backgroundSecondary}
      />
    );
  };

  const RenderInput = () => {
    if (mask) return RenderMaskedInput();
    if (type === 'password') return RenderPasswordInput();
    return RenderDefaultInput();
  };

  return (
    <Container style={{ position: 'relative' }}>
      {RenderInput()}
      {label && (
        <LabelContainer
          style={{
            backgroundColor: theme.color.backgroundSecondary,
            top: outFormItem ? '10px' : '-5px',
          }}
        >
          {label}
          {required && (
            <RequiredSpan color={theme.color.danger}>
              ({translate.basic.required.text})
            </RequiredSpan>
          )}
        </LabelContainer>
      )}
    </Container>
  );
}

export default memo(AntInput);
