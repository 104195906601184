//import { addMinutes } from 'date-fns';

//let debug = false;

export function WalletFormatParser(value) {
  if (value % 1 === 0) {
    const newValue = value * 1000000;
    return newValue;
  } else {
    const newValue = value * 1000000;
    return newValue;
  }
}

export const AmericanDateParser = (value) => {
  const date = value.split('/');
  const newDate = `${date[2]}-${date[1]}-${date[0]}`;
  return newDate;
  /*
    //var n = d.getTimezoneOffset();
    if(debug) console.log('date -> ', date);
    const newDate = addMinutes(new Date(date), 3);
    if(debug) console.log('newDate -> ', newDate);
    //return '1993-06-15T21:00:00.000Z';
    //return '1993-06-10';
    return '1993-06-13 23:00:00.000Z'
    */
};
