import React, { useContext, useEffect } from 'react';
//Contexts
import { AuthContext } from '~/contexts/AuthContext';
//Pages
import Login from './Login';
import Management from './Management';
// Environments
import Debug from '~/environments/Debug';
const isDebug = Debug.Accounts;

export default function AuthenticationCheck() {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      if (isDebug) console.log('😀 User logged in');
    } else {
      if (isDebug) console.log('🚫 User not logged in');
    }
  }, [user]);

  return user ? <Management /> : <Login />;
}
