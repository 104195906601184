import React, { useContext, memo } from 'react';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Libraries
import { Checkbox } from 'antd';

function AntCheckbox({ text, onChange, disabled, defaultChecked, checked }) {
  const { theme } = useContext(ThemeContext);
  return (
    <Checkbox
      onChange={onChange}
      disabled={disabled}
      defaultChecked={defaultChecked}
      checked={checked}
      style={{ color: theme.color.textOnSecondaryBackground }}
    >
      {text}
    </Checkbox>
  );
}

export default memo(AntCheckbox);
