import React from 'react';
// Components
import Text from '~/components/typography/text';
import Avatar from '~/components/avatar';
// Standalone Components
import { Container, TextContainer } from './style';
// Utils
import { UserShortName, UserAvatar, UserMainDocument } from '~/utils/UserInformation';

export default function UserInformation() {
    return (
        <Container>
            <Avatar src={UserAvatar()} size={75} style={{ marginRight: 10 }} />
            <TextContainer>
                <Text strong style={{ fontSize: "12pt" }}>{UserShortName()}</Text>
                <Text kind="secondary">{UserMainDocument()}</Text>
            </TextContainer>
        </Container>
    )
}