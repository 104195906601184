import Activity from './activity';
import Auth from './auth';
import Gateway from './gateway';
import Partner from './partner';
import Profile from './profile';
import QRCode from './qrcode';
import System from './system';
import Wallet from './wallet';

const Request = {
  Activity,
  Auth,
  Gateway,
  Partner,
  Profile,
  QRCode,
  System,
  Wallet
};

export default Request;
