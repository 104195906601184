import React, { useContext, useState } from 'react';
// Libraries
import { UserOutlined } from '@ant-design/icons';
import { message } from 'antd';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Standalone Components
import { Container, ButtonsContainer } from './style';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
// Environments
import Debug from '~/environments/Debug';
// Components
import Button from '~/components/button';
import Input from '~/components/input';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
// Utils
import { cpfMaskContinuos, cnpjMask } from '~/utils/Masks';
import { validateCPF, validateCNPJ } from '~/utils/Validations';

const isDebug = Debug.Modules.Authentication;

//console.log(navigator);

export default function VerificationStep({
  setStep,
  login,
  setLogin,
  setPerson,
}) {
  const { translate } = useContext(LanguageContext);
  const [loadingButton, setLoadingButton] = useState();

  const HandleChangeLogin = () => (event) => {
    if (event.target.value.length > 18) {
      return;
    }
    if (event.target.value.length <= 14) {
      return setLogin(cpfMaskContinuos(event.target.value));
    } else if (event.target.value.length > 14) {
      return setLogin(cnpjMask(event.target.value));
    }
  };

  async function Verify() {
    setLoadingButton(true);
    if (isDebug) console.log('🚀 API: Auth Verify');
    const params = {
      operation: ['Auth', 'Verify'],
      data: {
        login: login.replace(/[^\d]+/g, ''),
      },
    };
    if (isDebug) console.log('📦 Params: ', params);
    const response = await SocialMeAPI(params);
    if (isDebug) console.log('  ⚡ API Response, Auth Verify: ', response);
    if (response.success) {
      if (response.message === 'No person with this login was found') {
        if (isDebug) console.log('    No person with this login was found');
        if (!validateCPF(login) && login.length === 14) {
          message.error(translate.fields.invalid.CPF.text);
        } else if (!validateCNPJ(login) && login.length === 18) {
          message.error(translate.fields.invalid.CNPJ.text);
        } else if (login.length === 18) {
          setStep('Register Legal Person');
        } else {
          setStep('Register Physical Person');
        }
        return setLoadingButton(false);
      }
      if (response.message === 'A person with this login was found') {
        setPerson(response.body.person);
        if (isDebug) console.log('    A person with this login was found');
        setStep('Login');
        return setLoadingButton(false);
      }
      if (response.message === 'Pre-registration found') {
        if (isDebug) console.log('    Pre-registration found');
        setStep('Register Physical Person');
        return setLoadingButton(false);
      }
      if (response.message === 'Login parameter not sent') {
        if (isDebug) console.log('    Login parameter not sent');
        return null;
      }
    }
  }

  return (
    <Container>
      <Title level={4} style={{ textAlign: 'center' }}>
        {translate.authentication.verification.text}
      </Title>
      <Text type="secondary">
        {translate.authentication.verificationDescription.text}
      </Text>
      <Input
        margin="20px 0px 10px"
        label={
          login.length === 14
            ? translate.fields.label.CPF.text
            : login.length > 14
            ? translate.fields.label.CNPJ.text
            : translate.fields.label.CPFOrCNPJ.text
        }
        outFormItem
        prefix={<UserOutlined />}
        onChange={HandleChangeLogin()}
        value={login}
        size="large"
      />

      <ButtonsContainer>
        <Button
          type="primary"
          size="large"
          onClick={() => Verify()}
          loading={loadingButton}
          disabled={login.length === 14 || login.length === 18 || login === '006.176.008' ? false : true}
        >
          {translate.basic.next.text}
        </Button>
      </ButtonsContainer>
    </Container>
  );
}
