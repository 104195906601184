import React from 'react';
// Components
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';

export default function Terms() {
  return (
    <div>
      <Title style={{ fontSize: '12pt' }} level={4}>
        TERMOS E CONDIÇÕES GERAIS DE USO DA SOCIALME
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Estes Termos e condições gerais aplicam-se ao uso dos serviços
        oferecidos pela SOCIALME BENEFÍCIOS E SERVIÇOS S.A., inscrita no CNPJ/MF
        sob o nº. 38.100.911/0001-63, com sede na cidade de Boa Vista, Estado de
        Roraima, na Rua Jaqueira, Brasil, nº. 623, Caçari, CEP: 69307-410,
        denominada a partir deste momento como SOCIALME.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Qualquer pessoa, doravante nominada USUÁRIO, que pretenda utilizar os
        serviços da SOCIALME, deverá aceitar estes Termos e condições gerais,
        nossa Política de Privacidade e Política de Cookies.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        A aceitação destes Termos e Condições Gerais, bem como das políticas
        supracitadas é absolutamente indispensável à utilização da PLATAFORMA e
        dos serviços prestados pela SOCIALME.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        O USUÁRIO deverá ler, certificar-se de haver compreendido integralmente
        e aceitar todas as condições estabelecidas nos Termos e Condições
        Gerais, na Política de Privacidade e Política de Cookies assim como nos
        demais documentos a eles incorporados por referência, antes de efetuar
        seu cadastro como USUÁRIO da PLATAFORMA da SOCIALME.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        AO USAR A PLATAFORMA, O USUÁRIO DECLARA TER CAPACIDADE PLENA PARA OS
        ATOS DA VIDA CIVIL, NOS TERMOS DA LEGISLAÇÃO VIGENTE, CONCORDA COM ESTES
        TERMOS E CONDIÇÕES GERAIS DE USO DA PLATAFORMA, POLÍTICA DE PRIVACIDADE
        E POLÍTICA DE COOKIES.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        EM CASO DE USUÁRIO MENOR DE 18 (DEZOITO) ANOS, OU MAIOR INCAPAZ, ESTE
        DEVERÁ TER A AUTORIZAÇÃO EXPRESSA DE SEUS PAIS OU RESPONSÁVEIS PARA O
        USO DA PLATAFORMA, AUTORIZAÇÃO ESSA QUE SE DARÁ POR MEIO DO ACEITE DO
        VÍNCULO FAMILIAR NA ÁREA DE FAMÍLIAS DO PERFIL CADASTRAL.
      </Text>
      <br />

      <Title style={{ fontSize: '12pt' }} level={4}>
        01 - OBJETO
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Os serviços, objeto do presente Termo, consistem na criação espontânea
        de relações entre pessoas e instituições de direito público e privado
        que integrem comunidades do mesmo âmbito geográfico, interesses
        particulares, interesses comuns ou simplesmente buscam produtos ou
        serviços para aquisição ou venda.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Dessa maneira, a SOCIALME fornecerá ao USUÁRIO (apenas conforme e quando
        o serviço estiver disponível) acesso a oportunidades de estabelecer
        relações, reunir dados, contatos e acordos comerciais entre os USUÁRIOS.
        O cadastro na plataforma é gratuito, os serviços providos pela SOCIALME
        ou demais integrantes da plataforma (pessoas física ou jurídica) podem
        ser cobrados, sempre que houver algum custo ao USUÁRIO, o mesmo dará seu
        de acordo através de contratos e termos complementares apresentados na
        plataforma SOCIALME.
      </Text>
      <br />

      <Title style={{ fontSize: '12pt' }} level={4}>
        02 – DECLARAÇÕES DO USUÁRIO
      </Title>
      <Text style={{ fontSize: '9pt' }}>O USUÁRIO declara que:</Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        a) não utilizará a PLATAFORMA para fins ilegais ou para transmitir
        material ilegal, difamatório, que viole a privacidade de terceiros, ou
        que seja abusivo, ameaçador, obsceno, ou de qualquer outra forma
        censurável, ou que infrinja ou possa infringir direitos de propriedade
        intelectual ou outros direitos de terceiros;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        b) não utilizará a PLATAFORMA para transmitir quaisquer materiais que
        contenham vírus, arquivos do tipo "Cavalo de Tróia", ou outro programa
        prejudicial.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        c) não transmitirá mensagens não solicitadas ("spam" ou "junk mail"),
        correntes ("chain letters"), ou distribuição em massa de mensagens não
        solicitadas;{' '}
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        d) não interferirá ou interromperá o serviço, servidores ou redes
        conectadas à PLATAFORMA;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        e) cumprirá todos os requerimentos, procedimentos, políticas e
        regulamentos de redes conectadas à PLATAFORMA;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        f) cumprirá todas as leis aplicáveis com relação à transmissão de dados
        a partir do Brasil ou do território onde o usuário reside;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        g) não transmitirá qualquer material ilegal, vexatório, difamatório,
        invasivo à privacidade, abusivo, ameaçador, prejudicial, vulgar,
        obsceno, injurioso, ou de qualquer forma censurável através da
        PLATAFORMA;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        h) não transmitirá qualquer material que viole direitos de terceiros,
        incluindo, mas não se limitando, direitos de propriedade intelectual de
        terceiros;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        i) não transmitirá qualquer material que viole lei ou regulamento local,
        estadual, nacional ou internacional aplicável;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        j) não obterá ou tentará obter acesso não autorizado a outros sistemas
        ou redes de computadores conectados à PLATAFORMA;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        k) é o único responsável pelo conteúdo das transmissões feitas por meio
        da PLATAFORMA, a partir de seu endereço eletrônico para e-mail e senha
        cadastrados;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        l) concorda em ter seu cadastro cancelado caso a SOCIALME venha a
        identificar que o usuário tenha violado de algum modo estes termos ou
        quaisquer políticas divulgadas por meio da PLATAFORMA.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        m) não copie, modifique, distribua, venda ou alugue qualquer parte dos
        nossos serviços ou softwares. Além disso, não faça engenharia reversa
        nem tente extrair qualquer parte do nosso código-fonte, exceto em casos
        em que você tenha nossa permissão por escrito ou em casos em que a
        legislação permita que você faça isso.
      </Text>
      <br />

      <Title style={{ fontSize: '12pt' }} level={4}>
        03 – DA PRIVACIDADE
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Faz parte da política da SOCIALME respeitar a privacidade de seus
        usuários. A SOCIALME não irá, portanto, monitorar, editar, acessar ou
        divulgar informações relativas ao conteúdo das comunicações privativas
        entre seus usuários, exceto nos casos expressamente previstos nos termos
        deste contrato ou a menos que seja obrigada a fazê-lo em virtude de lei
        ou ordem judicial, nos termos de nossa Política de Privacidade.
      </Text>
      <br />

      <Title style={{ fontSize: '12pt' }} level={4}>
        04 – RESPONSABILIDADES DO USUÁRIO
      </Title>
      <Text style={{ fontSize: '9pt' }}>Fica a cargo do USUÁRIO:</Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        a) providenciar o seu próprio acesso à Internet e pagar todas as taxas
        de serviço eventualmente cobradas por terceiros com relação a tal
        acesso;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        b) providenciar seu próprio acesso a um endereço para envio de mensagens
        eletrônicas via Internet e pagar todas as taxas de serviço eventualmente
        cobradas por terceiros com relação a tal acesso;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        c) providenciar todo o equipamento necessário para efetuar sua conexão à
        Internet, incluindo, mas não se limitando, a um computador e um modem
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        d) efetuar seu próprio cadastro na plataforma.
      </Text>
      <br />

      <Title style={{ fontSize: '12pt' }} level={4}>
        05 – RESPONSABILIDADES DA SOCIALME:
      </Title>
      <Text style={{ fontSize: '9pt' }}>Fica a cargo da SOCIALME:</Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        a) disponibilizar ferramentas acessíveis via Internet de cadastro de
        usuário;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        b) disponibilizar ferramentas de composição de mensagem eletrônica,
        acessíveis via Internet, por meio de cadastramento de seus USUÁRIOS;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        c) A SOCIALME envidará os melhores esforços para assegurar a integridade
        dos dados cadastrados pelos USUÁRIOS.
      </Text>
      <br />

      <Title style={{ fontSize: '12pt' }} level={4}>
        06 - CADASTRO DO USUÁRIO, SENHA E SEGURANÇA
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        O USUÁRIO se compromete a informar seu próprio CPF/CNPJ a ser utilizado
        para sua identificação na PLATAFORMA SOCIALME e recebimento de mensagens
        advindas de seu cadastro na referida PLATAFORMA. O USUÁRIO criará uma
        senha, sendo inteiramente responsável pela confidencialidade da sua
        senha, bem como de qualquer atividade que ocorra no âmbito de seu
        cadastro. O USUÁRIO pode alterar sua senha a qualquer momento. Tanto
        pelo CPF/CNPJ informado pelo usuário quanto a senha por ele criada serão
        utilizados para a identificação do mesmo USUÁRIO e permissão de acesso à
        PLATAFORMA.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        O USUÁRIO se compromete a notificar imediatamente a SOCIALME sobre
        qualquer uso não autorizado de seu login na PLATAFORMA (identificação do
        usuário para acesso à PLATAFORMA mediante o preenchimento de CPF/CNPJ e
        senha criada) ou qualquer quebra de segurança de seu conhecimento.
        Compromete-se, ainda, a não deixar seu cadastro aberto em seu computador
        após entrar com seu endereço eletrônico e senha.
      </Text>
      <br />

      <Title style={{ fontSize: '12pt' }} level={4}>
        07 - DIREITOS DE PROPRIEDADE
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        O USUÁRIO reconhece e concorda que o conteúdo, incluindo, mas não se
        limitando a textos, programas de software, músicas, sons, fotografias,
        gráficos, vídeos ou outros materiais contidos em publicidade ou
        propaganda disponíveis na PLATAFORMA, bem como as informações divulgadas
        ao USUÁRIO por meio da PLATAFORMA, podem estar protegidos por direitos
        autorais, marcas, patentes ou outros direitos de propriedade intelectual
        e legislação aplicável.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        O USUÁRIO reconhece e concorda que somente poderá utilizar tais
        materiais e informações conforme expressamente autorizado pela SOCIALME
        ou pelos parceiros, e não poderá copiar, reproduzir, transmitir,
        distribuir ou criar obras derivadas a partir de tais materiais ou
        informações sem a autorização expressa do respectivo proprietário.
      </Text>
      <br />

      <Title style={{ fontSize: '12pt' }} level={4}>
        08 - GARANTIA LIMITADA
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        A. A UTILIZAÇÃO DA PLATAFORMA É DE INTEIRA RESPONSABILIDADE DO USUÁRIO.
        O SERVIÇO É FORNECIDO GRATUITAMENTE E DEPENDE DA FUNCIONALIDADE DE
        VÁRIOS FATORES, COMO A INTERAÇÃO DE SERVIDORES E SERVIÇOS DE
        TELECOMUNICAÇÕES DE TERCEIROS, A ADEQUAÇÃO DOS EQUIPAMENTOS DO USUÁRIO E
        DO DESTINATÁRIO DA MENSAGEM, ETC. TENDO ISSO EM CONSIDERAÇÃO, A SOCIALME
        EMPREGARÁ SEUS MELHORES ESFORÇOS PARA ASSEGURAR QUE OS SERVIÇOS
        DESCRITOS NESTES PRESENTES TERMOS SEJAM PRESTADOS SEM FALHAS OU
        INTERFERÊNCIAS EXTERNAS. NO ENTANTO, CONSIDERANDO A NATUREZA DO SERVIÇO
        E O ATUAL ESTADO DA TÉCNICA REFERENTE ÀS COMUNICAÇÕES E TRANSMISSÕES
        ELETRÔNICAS DE DADOS VIA INTERNET, A SOCIALME NÃO PODE GARANTIR QUE
        MENSAGENS SERÃO ENTREGUES PARA A PESSOA CORRETA, EM UM PRAZO ADEQUADO,
        OU NÃO SOFRERÃO EXTRAVIO, DIVULGAÇÃO OU VIOLAÇÃO POR PARTE DE TERCEIROS
        NÃO AUTORIZADOS, COMO POR EXEMPLO "HACKERS". O USUÁRIO É ACONSELHADO A
        NÃO CONFIAR EXCLUSIVAMENTE NO SERVIÇO PARA ENVIAR INFORMAÇÕES
        IMPORTANTES OU CONFIDENCIAIS, NÃO HAVENDO RESPONSABILIDADE DA SOCIALME
        POR QUALQUER FALHA RESULTANTE DO ENVIO DE MENSAGENS PELO USUÁRIO;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        B. A SOCIALME NÃO OFERECE GARANTIAS DE QUALQUER NATUREZA COM RELAÇÃO A
        EVENTUAIS FALHAS DO SERVIÇOS;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        C. A SOCIALME NÃO GARANTE QUE O SERVIÇO SERÁ ADEQUADO ÀS NECESSIDADES DO
        USUÁRIO OU QUE O SERVIÇO SERÁ PRESTADO DE MANEIRA ININTERRUPTA, SEGURA,
        OU LIVRE DE ERROS.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        D. O USUÁRIO RECONHECE E CONCORDA QUE QUALQUER MATERIAL E/OU INFORMAÇÃO
        OBTIDA POR MEIO DA PLATAFORMA É DE SUA INTEIRA RESPONSABILIDADE,
        INCLUSIVE QUANTO A EVENTUAIS DANOS AO SEU SISTEMA DE COMPUTADOR.
      </Text>
      <br />

      <Title style={{ fontSize: '12pt' }} level={4}>
        09 - INDENIZAÇÃO
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        O USUÁRIO concorda em indenizar e isentar A SOCIALME, seus diretores e
        empregados, de quaisquer perdas, despesas, danos, reclamações ou
        reivindicações, incorridos por quaisquer terceiros ou sofridos pela
        SOCIALME com relação ou em decorrência de qualquer uso ou transmissão
        realizada pelo USUÁRIO, por meio da PLATAFORMA, que ocorra sob o
        CPF/CNPJ e a senha do USUÁRIO, e que viole os termos de uso da
        PLATAFORMA, Política de Privacidade e/ou quaisquer leis ou regulamentos
        locais, nacionais ou internacionais aplicáveis, ou quaisquer direitos de
        terceiros.
      </Text>
      <br />

      <Title style={{ fontSize: '12pt' }} level={4}>
        10 - RESCISÃO
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        A SOCIALME poderá cancelar o acesso do USUÁRIO a qualquer parte ou a
        toda PLATAFORMA, aos serviços a ela relacionados a qualquer momento, com
        ou sem motivo, com ou sem aviso prévio, a critério exclusivo da
        SOCIALME.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Se o USUÁRIO desejar encerrar seu cadastro, poderá solicitar à
        administração da SOCIALME a exclusão de seu cadastro, que deve ser
        encaminhada por e-mail ao endereço suporte@socialme.com.br.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        A SOCIALME não será responsável por manter qualquer conteúdo vinculado
        ao cadastro do USUÁRIO e para enviar qualquer mensagem não lida ou não
        enviada pelo usuário ou terceiros.
      </Text>
      <br />

      <Title style={{ fontSize: '12pt' }} level={4}>
        11 - DO USO DE INFORMAÇÕES DO CADASTRO
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        A. SERÁ FACULTADO AO USUÁRIO PERMITIR A SOCIALME OU ALGUÉM POR ELE
        INDICADO, A REVELAR TODAS OU QUALQUER INFORMAÇÕES DE CADASTRO E
        INFORMAÇÕES SOBRE A UTILIZAÇÃO DA PLATAFORMA PELO USUÁRIO, PARA FINS
        COMERCIAIS, INCLUSIVE DE MARKETING;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        B. O USUÁRIO AUTORIZA EXPRESSAMENTE O RECEBIMENTO DE MENSAGENS, EM SEU
        ENDEREÇO ELETRÔNICO CADASTRADO, REFERENTES A CONTEÚDO PUBLICITÁRIO POR
        PARTE DA SOCIALME OU QUAISQUER USUÁRIOS QUE SOCIALME VENHA A AUTORIZAR;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        C. O USUÁRIO CONCORDA EXPRESSAMENTE QUE A SOCIALME NÃO TERÁ QUALQUER
        RESPONSABILIDADE SOBRE A UTILIZAÇÃO DAS INFORMAÇÕES POR ELA REVELADAS
        CONFORME EXPRESSAMENTE AUTORIZADO, PELO QUE O USUÁRIO EXPRESSAMENTE
        ISENTA A SOCIALME DE TODA E QUALQUER RESPONSABILIDADE, INCLUINDO, MAS
        NÃO SE LIMITANDO, À TRANSMISSÃO AO USUÁRIO DE MENSAGENS QUE CONTENHAM
        MATERIAL (I) ILEGAL, VEXATÓRIO, DIFAMATÓRIO, INVASIVO À PRIVACIDADE,
        ABUSIVO, AMEAÇADOR, PREJUDICIAL, VULGAR, OBSCENO, INJURIOSO, OU DE
        QUALQUER FORMA CENSURÁVEL; (II) QUE VIOLE DIREITOS DE TERCEIROS,
        INCLUINDO, MAS NÃO SE LIMITANDO, A DIREITOS DE PROPRIEDADE INTELECTUAL
        DE TERCEIROS; (III) QUE VIOLE QUALQUER LEI OU REGULAMENTO LOCAL,
        ESTADUAL, NACIONAL OU INTERNACIONAL APLICÁVEL; (IV) QUE CONTENHA VÍRUS,
        ARQUIVOS DO TIPO "CAVALO DE TRÓIA", OU OUTRO PROGRAMA PREJUDICIAL; OU
        (V) NÃO-SOLICITADO ("SPAM" OU "JUNK MAIL"), CORRENTES ("CHAIN LETTERS").
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        D. FICA, AINDA, FACULTADO AO USUÁRIO PERMITIR QUE A SOCIALME DIVULGUE
        TODA OU QUALQUER INFORMAÇÃO DE CADASTRO, BEM COMO INFORMAÇÕES SOBRE A
        UTILIZAÇÃO DO SERVIÇO PELO USUÁRIO.
      </Text>
      <br />

      <Title style={{ fontSize: '12pt' }} level={4}>
        12 - ALTERAÇÃO DOS TERMOS DE USO E POLÍTICA DE PRIVACIDADE
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        A SOCIALME poderá eventualmente modificar os termos de uso da PLATAFORMA
        e/ou Política de Privacidade, sendo o USUÁRIO notificado por meio de uma
        tela inicial, que descreverá as modificações. Para concordar com as
        mudanças o USUÁRIO deverá clicar novamente o botão "Eu Aceito". Caso
        contrário, o uso da PLATAFORMA pelo usuário será cancelado.
      </Text>
      <br />
    </div>
  );
}
