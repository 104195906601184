import styled from 'styled-components';
//Environments
import System from '~/environments/System';

const responsiveBreakpoint = `${System.responsiveBreakpoint}px`;

export const Container = styled.div`
  background-color: ${(style) => style.backgroundColor || '#FFFFFF'};
  min-height: 100vh;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  @media (max-width: ${responsiveBreakpoint}) {
    width: 100%;
    padding: 10px;
  }
`;
export const Content = styled.div`
  background-color: ${(style) => style.backgroundColor || '#FFFFFF'};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
`;
export const Card = styled.div`
  width: 300px;
  background-color: ${(style) => style.backgroundColor || '#FFFFFF'};
  text-align: center;
  padding: 0px 30px 10px;
  border: 1px solid ${(style) => style.borderColor || '#000'};
  border-radius: 8px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  @media (max-width: ${responsiveBreakpoint}) {
    border: none;
  }
`;
export const BrandContainer = styled.div`
  width: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Brand = styled.img`
  width: auto;
  height: 45px;
`;
