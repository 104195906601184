export default {
  SM00EF13A: {
    message: 'Browser token successfully created',
  },
  SM9DC07A7: {
    message: 'Successfully registered person',
  },
  SMC9DFC93: {
    message: 'The file was successfully created',
    //Profile.Avatar.Change
  },
  SM927FEDF: {
    message: 'The address was successfully created',
    //Profile.Address.Add
  },
  SM17C7C0C: {
    message: 'The contact was successfully created',
    //Profile.Contact.Add
  },
  SM9BC74C7: {
    message: 'The relationship was successfully created',
    //Profile.Relantionship.Add
  },
  SM2408E47: {
    message: 'The person pre register was successfully created',
    //Person.PreRegistration
  },
  SM2ACBEAB: {
    message: 'The qr-code was successfully created',
  },
  SM206DB5D: {
    message: 'The place was successfully created',
  },
  SM961DFCB: {
    message: "The 'Device' was successfully created",
  },
  SM7D4C2FB: {
    message: "The 'activity' was successfully created",
  },
  SM594912D: {
    message: "The 'Custom question' was successfully created",
  },
  SME60A688:{
    message: "The 'activity_frequency' was successfully created",
  },
  SM7B1AC19: {
    message: "The 'product/service offering' was successfully created",
  },
  SM81A593E: {
    message: "The 'operation intent' was successfully created",
  }
};
