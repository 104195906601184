const API = {
  //socialMe: 'https://production-socialme-earth-api.herokuapp.com/v1',
  //socialMe: 'https://development-socialme-earth-api.herokuapp.com/v1',
  //https://production-socialme-earth-api.herokuapp.com/
  //socialMe: 'http://25.17.12.216:3001/v2',
  //socialMe: 'https://socialme-pg-dev.herokuapp.com/v2',
  //socialMe: 'http://192.168.2.13:3001/v2',
  // socialMe : 'https://socialme-com-br.herokuapp.com/v1',
  // socialMe: 'https://dev-socialme-com-br.herokuapp.com/v1',
  socialMe: 'https://api.sigam.socialme.com.br/v1',
};

export default API;
