import React, { useContext } from 'react';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Utils
import { GetBrandIcon } from '~/utils/SocialMeAssets';
//Standalone Components
import { Container, Content, BrandContainer, Brand, Card } from './style';
// Own Components
import UserContainer from './components/UserContainer';
import Menu from './components/Menu';
import LogoutButton from './components/LogoutButton';
//Components
import Divider from '~/components/divider';

export default function Management() {
  const { theme } = useContext(ThemeContext);
  return (
    <>
      <Container style={{ backgroundColor: theme.color.backgroundSecondary }}>
        <Content style={{ backgroundColor: theme.color.backgroundSecondary }}>
          <Card
            style={{
              borderColor: theme.color.borderColor,
              backgroundColor: theme.color.backgroundSecondary,
            }}
          >
            <BrandContainer>
              <Brand src={GetBrandIcon('socialMeVariation2')} alt="Social Me" />
            </BrandContainer>
            <Divider style={{ margin: '0px 0px 10px' }} />
            <UserContainer />
            <Divider style={{ margin: '10px 0px' }} />
            <Menu />
            <Divider style={{ margin: '10px 0px' }} />
            <LogoutButton />
          </Card>
        </Content>
      </Container>
    </>
  );
}
