import React, { useContext } from 'react';
// Libraries
import { Avatar } from 'antd';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Text from '~/components/typography/text';
// Standalone Components
import { Container, Content } from './style';
// Utils
import { CapitalizeString } from '~/utils/Transformations';
import { GetDefaultAvatar } from '~/utils/SocialMeAssets';

export default function UserContainer({ person }) {
  const { theme } = useContext(ThemeContext);
  function GetName() {
    if (person.social_name) {
      return CapitalizeString(person.social_name);
    }
    return CapitalizeString(person.name);
  }
  function GetAvatar() {
    if (person.avatar) return person.avatar;
    if (person.kind === 'PF') {
      if (person.social_sex) {
        if (person.social_sex === 'M') {
          return GetDefaultAvatar('male');
        }
        return GetDefaultAvatar('feminine');
      }
      if (person.sex === 'M') {
        return GetDefaultAvatar('male');
      }
      return GetDefaultAvatar('feminine');
    }
    if (person.kind === 'PJ') {
      return GetDefaultAvatar('partner');
    }
  }
  return (
    <Container>
      <Content borderColor={theme.color.borderColor}>
        <Avatar size={25} style={{ marginRight: 5 }} src={GetAvatar()} />
        <Text strong>{GetName()}</Text>
      </Content>
    </Container>
  );
}
