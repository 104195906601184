export default {
  SM39B2F64: {
    message: 'Browser Successfully Invalidated',
  },
  SM8F68D56: {
    message: "The parameter 'usrtkn' that was sent is invalid",
  },
  SM9A0C067: {
    message: 'Invalid intent',
  },
  SM1CFF412: {
    message:
      "there aren't a valid 'gateway_contract' that applies to this operation",
  },
  SM3513B54: {
    message: "The parameter 'new_password' that was sent is invalid",
  },
  SM193C605: {
    message: "The parameter 'wllttkn' that was sent is invalid",
  },
  SM80725F5: {
    message: "The parameter 'login or password' that was sent is invalid",
  },
  SM154AA68: {
    message: "The parameter 'account or password' that was sent is invalid",
  },
  SMBA78EB6: {
    message: "The process encountered the following error 'unknown'",
  },
  SMA6FAB33: {
    message: "The parameter 'id' that was sent is invalid",
  },
};
