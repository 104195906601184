import React, { useContext, useState } from 'react';
// Libraries
import { Form, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';
import { LanguageContext } from '~/contexts/LanguageContext';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
// Own Components
import UserContainer from './components/UserContainer';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Button from '~/components/button';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
// Environments
import Debug from '~/environments/Debug';
// Standalone Components
import { ButtonsContainer } from './style';

const isDebug = Debug.Modules.Authentication;

export default function LoginStep({ login, setStep, person }) {
  const { translate } = useContext(LanguageContext);
  const { PopulateUser } = useContext(AuthContext);

  const [form] = Form.useForm();
  const [loadingButton, setLoadingButton] = useState(false);

  async function Login(data) {
    setLoadingButton(true);
    if (isDebug) console.log('🚀 API: Auth Login');
    const params = {
      operation: ['Auth', 'Login'],
      data,
    };
    if (isDebug) console.log('📦 Params: ', params);
    const response = await SocialMeAPI(params);

    if (isDebug) console.log('  ⚡ API Response, Auth Login: ', response);
    console.log(response)
    if (response.success) {
      if (response.message === 'Login successfully') {
        if (isDebug) console.log('    Login successfully');
        message.success(translate.authentication.loginSuccessfully.text);
        return PopulateUser(response.body.person, response.body.usrtkn);
      }
    } else {
      if (
        response.message ===
        "The parameter 'login or password' that was sent is invalid"
      ) {
        if (isDebug) console.log('    Invalid login');
        message.error(translate.authentication.invalidLogin.text);
        setLoadingButton(false);
      }
      if (response.message === 'Incorrect parameter sent') {
        if (isDebug) console.log('    Incorrect parameter sent');
        message.error(translate.authentication.checkTheFieldsAndTryAgain.text);
        setLoadingButton(false);
      }
    }
  }
  async function onFinish(data) {
    data.login = data.login.replace(/[^\d]+/g, '');
    data.keep = true;
    Login(data);
  }

  return (
    <div>
      <Title style={{ textAlign: 'center' }} level={4}>
        {translate.authentication.welcome.text}
      </Title>
      <UserContainer person={person} />
      <Text type="secondary" style={{ textAlign: 'center' }}>
        {translate.authentication.enterYourPasswordToAccessTheSystem.text}
      </Text>
      <Form
        labelCol={{
          xs: { span: 24 },
          sm: { span: 24 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 24 },
        }}
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          login: login,
        }}
      >
        <FormItem
          name={['login']}
          style={{ margin: '20px 0px 10px' }}
          item={
            <Input
              label={
                login.length === 14
                  ? translate.fields.label.CPF.text
                  : translate.fields.label.CNPJ.text
              }
              prefix={<UserOutlined />}
              size="large"
              disabled
            />
          }
        />
        <FormItem
          name={['password']}
          rules={[
            {
              required: true,
              message: translate.fields.required.password.text,
            },
          ]}
          item={
            <Input
              margin="10px 0px 0px"
              prefix={<LockOutlined />}
              label={translate.fields.label.password.text}
              type="password"
              size="large"
            />
          }
        />

        <ButtonsContainer>
          <Button onClick={() => setStep('Verification')} size="large">
            {translate.basic.back.text}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingButton}
            size="large"
          >
            {translate.basic.next.text}
          </Button>
        </ButtonsContainer>
      </Form>
    </div>
  );
}
