import React from 'react';
// Components
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Divider from '~/components/divider';

export default function Terms() {
  return (
    <div>
      <Divider />
      <Title level={4}>POLÍTICA DE PRIVACIDADE</Title>
      <Title level={4} style={{ fontSize: '12pt' }}>
        1. INFORMAÇÕES
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        A SOCIALME tem por compromisso cuidar da privacidade de seus usuários e
        agir nos exatos termos da legislação vigente e, em especial, quando se
        fala de privacidade, conferir integral observância e cumprimento à Lei
        13.709 de 14 de agosto de 2018, Lei Geral de Proteção de Dados (“LGPD”).
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Nesse contexto, criou a presente política, com vistas a informar o
        usuário acerca da forma de tratamento de seus dados, respeitando-se os
        princípios da boa-fé, finalidade, adequação, necessidade, livre acesso,
        qualidade de dados, transparência, segurança, prevenção, não
        discriminação e responsabilização.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        AO USAR A PLATAFORMA, O USUÁRIO DECLARA TER CAPACIDADE PLENA PARA OS
        ATOS DA VIDA CIVIL, NOS TERMOS DA LEGISLAÇÃO VIGENTE, E CONCORDA COM A
        COLETA E USO DE SEUS DADOS PESSOAIS E OUTRAS INFORMAÇÕES PELA SOCIALME,
        CONFORME DESCRITO NESTA POLÍTICA DE PRIVACIDADE.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        2. DEFINIÇÕES
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        USUÁRIO: todas as pessoas físicas representando a si mesmo ou
        representando seus dependentes menores de 18 anos ou representando
        pessoas jurídicas, titulares de dados pessoais e informações fornecidas
        a SOCIALME, por meio da PLATAFORMA, bem como aquelas que visitarão e/ou
        utilização a referida PLATAFORMA, que sejam totalmente capazes para a
        prática dos atos da vida civil, assim considerados os maiores de 18
        (dezoito) anos ou emancipados e que possam, de maneira livre e
        consciente, manifestar sua vontade; e todas as pessoas físicas menores
        de 18 (dezoito) anos ou maiores incapazes, desde que devidamente
        representados pelos pais ou legalmente responsáveis.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        DADOS PESSOAIS: todas as informações fornecidas pelo usuário e coletadas
        pela SOCIALME, que identifiquem ou possam identificar o usuário e, por
        meio das quais, a identificação ou informações de contato de uma pessoa
        física possam ser derivadas. Os dados pessoais podem estar em qualquer
        mídia ou formato, inclusive registros eletrônicos ou computadorizados,
        bem como em arquivos baseados em papel. Os dados pessoais, no entanto,
        não incluem telefone comercial, número de celular comercial, endereço
        comercial, e-mail comercial e qualquer outro dado de pessoas jurídica.{' '}
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        FINALIDADE: propósitos legítimos de tratamento de dados do usuário,
        específicos, explícitos, informados ao usuário e por ele consentidos.{' '}
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        NECESSIDADE: limitação de tratamento de dados ao mínimo necessário à
        realização da finalidade, cuja abrangência atinja apenas os dados
        pertinentes, proporcionais e não excessivos.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        FUNDAMENTO LEGAL: legislação que torna legítimo o tratamento de dados
        pessoais, bem como contratos, termos e políticas, para uma determinada
        finalidade.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        CONSENTIMENTO: autorização expressa e inequívoca conferida pelo USUÁRIO
        a SOCIALME após a leitura e compreensão integral dos termos a serem
        autorizados, autorização essa que se dará por meio de cliques nas
        respectivas caixas, expressamente indicadas na PLATAFORMA, para fins de
        autorização de tratamento de dados pessoais e realização da finalidade.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        PLATAFORMA: Website e Aplicativo da SOCIALME, por meio dos quais são
        fornecidos, coletados e tratados os dados pessoais e outras informações.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        3. COLETA E USO DE DADOS PESSOAIS
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        O USUÁRIO está ciente de que fornece dados pessoais de forma consciente
        e voluntária, por meio do preenchimento do cadastro disponível na
        PLATAFORMA, sendo certo que referidos dados serão mantidos em sigilo,
        cuja utilização se dará apenas para o propósito que motivou o cadastro.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        4. SEGURANÇA DOS DADOS PESSOAIS
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Os dados pessoais serão armazenados na base de dados da SOCIALME, sendo
        certo que a SOCIALME utiliza diversos procedimentos de segurança e
        integridade dos dados pessoais, com vistas a protegê-los, evitando,
        dessa maneira, eventuais danos decorrentes do tratamento desses dados.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Não obstante a utilização de medidas de segurança e monitoramento pela
        SOCIALME, para verificar eventuais vulnerabilidades e ataques, para
        proteger os dados pessoais contra divulgação não autorizada, mau uso ou
        alteração, o USUÁRIO entende e concorda que não há garantias de que as
        informações não poderão ser acessadas, divulgadas, alteradas ou
        destruídas por violação de qualquer uma das proteções físicas, técnicas
        ou administrativas.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        5. RETENÇÃO DE DADOS
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        A SOCIALME retém todos os dados fornecidos, inclusive os dados pessoais,
        pelo período em que o cadastro do USUÁRIO permanecer ativo e conforme
        seja necessário para consecução de seus serviços ou em detrimento de
        exigências de órgãos de controle e repressão a crimes ou eventuais
        medidas judiciais.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        A SOCIALME reterá os dados pessoais e outras informações e os manterá
        armazenados até eventual requerimento de exclusão por parte do USUÁRIO.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        A SOCIALME poderá vir a manter os dados pessoais após receber o pedido
        de exclusão por parte do USUÁRIO, caso seja necessário para cumprimento
        de obrigações legais, resolver disputas, manter a segurança, evitar
        fraudes e abuso e garantir o cumprimento de contratos.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        6. FUNDAMENTO LEGAL
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        A SOCIALME trata dados pessoais exclusivamente em situações em que está
        autorizada legalmente, mediante o expresso e inequívoco consentimento do
        USUÁRIO.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        A SOCIALME, nos termos da legislação vigente e consentimento do USUÁRIO,
        tem permissão para coletar, produzir, receptar, classificar, utilizar,
        acessar, reproduzir, transmitir, distribuir, processar, arquivar,
        armazenar, eliminar, avaliar ou controlar a informação, modificar,
        comunicar, transferir, difundir ou extrair dados sobre o USUÁRIO.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        O fundamento legal inclui, ainda, contratos e procedimentos preliminares
        contratuais (em que o processamento é necessário para firmar o contrato
        com o USUÁRIO e interesses legítimos, desde que tal processamento não
        viole seus direitos e liberdades.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Tais interesses incluem proteger o USUÁRIO de ameaças, cumprir a
        legislação aplicável, o exercício regular de direitos em processo
        judicial, administrativo ou arbitral, entender e melhorar os negócios e
        relacionamentos com os USUÁRIOS, bem como permitir que estes encontrem
        oportunidades econômicas.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        O USUÁRIO tem o direito de negar ou retirar o consentimento fornecido a
        SOCIALME, podendo esta encerrar a consecução de seus serviços para o
        USUÁRIO na hipótese de ocorrência de tal solicitação.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Caso o USUÁRIO tenha dúvidas sobre o fundamento legal para coleta,
        tratamento e armazenamento de seus dados pessoais, poderá entrar em
        contato com A SOCIALME, por meio de pessoa indicada por este, denominado
        ENCARREGADO, pelo e-mail: suporte@socialme.com.br.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        7. DIREITO AO USUÁRIO DE ACESSO E CONTROLE DOS DADOS PESSOAIS
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        O SOCIALME disponibiliza ao USUÁRIO opções de exclusão e/ou correção dos
        seus dados pessoais. O USUÁRIO pode:
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        I. Excluir Dados: o USUÁRIO pode solicitar a exclusão de alguns dos seus
        dados pessoais (por exemplo, se eles não são mais necessários para lhe
        fornecer os serviços).
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        II. Alterar ou corrigir dados: o USUÁRIO pode editar ou solicitar a
        edição de alguns dos seus dados pessoais. O USUÁRIO também pode
        solicitar atualizações, alterações ou correções de seus dados em
        determinados casos, principalmente se eles estiverem incorretos.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        III. Alterar suas preferências de compartilhamento de dados pessoais em
        seu perfil, no cadastro na SOCIALME.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        O USUÁRIO pode fazer as solicitações listadas acima por meio da
        PLATAFORMA, sendo que estes pedidos serão considerados de acordo com as
        leis aplicáveis.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        8. POLÍTICA DE DADOS
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Esta política descreve as informações processadas pela SOCIALME para
        viabilizar sua operação por meio da PLATAFORMA.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Quais tipos de informações coletamos no Social?
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Para fornecer os Serviços da DIREITO AO USUÁRIO DE ACESSO E CONTROLE DOS
        DADOS PESSOAIS, precisamos processar informações sobre o USUÁRIO. Os
        tipos de informações que coletamos dependem de como o USUÁRIO usa nossos
        Serviços, sendo que o cadastro inicial requer os seguintes dados: nome
        completo, nome da mãe, telefone ou e-mail, data de nascimento, sexo e
        CPF/CNPJ.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        9. COISAS QUE O USUÁRIO E OUTRAS PESSOAS FAZEM E FORNECEM.
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Informações para acesso ao sistema: o CPF/CNPJ informado no cadastro é
        coletado e armazenado com o objetivo de localizar suas informações
        personalizadas a cada novo acesso.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Informações e conteúdos que o USUÁRIO fornece: Coletamos o conteúdo,
        comunicações e outras informações que o USUÁRIO fornece quando usa
        nossos Serviços, inclusive quando o USUÁRIO se cadastra para criar uma
        conta, cria ou compartilha conteúdo, envia mensagens ou se comunica com
        outras pessoas. Isso pode incluir informações presentes ou sobre o
        conteúdo que o USUÁRIO fornece (como metadados), como a localização de
        uma foto ou a data em que um arquivo foi criado. Isso pode incluir
        também o que o USUÁRIO vê por meio dos recursos que fornecemos, como
        nossa câmera, de modo que possamos realizar ações como sugerir máscaras
        e filtros de que USUÁRIO pode gostar, ou dar dicas sobre o uso de
        formatos da câmera. Nossos sistemas processam automaticamente o conteúdo
        e as comunicações que o USUÁRIO e outras pessoas fornecem a fim de
        analisar o contexto e o conteúdo incluído nesses itens para as
        finalidades descritas abaixo.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Dados com proteções especiais: é possível optar por fornecer informações
        nos campos de perfil da PLATAFORMA, sobre as opções, interesses e
        preferências do USUÁRIO.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Redes e conexões: Coletamos informações sobre as pessoas, Páginas,
        contas, hashtags e grupos com que o USUÁRIO se conecta e sobre como o
        USUÁRIO interage com eles em nossos Serviços, por exemplo, as pessoas
        com quem o USUÁRIO mais se comunica ou os grupos dos quais o USUÁRIO faz
        parte. Também coletamos informações de contato se o USUÁRIO optar por
        carregá-las, sincronizá-las ou importá-las de um dispositivo (como uma
        agenda de contatos, registro de chamadas ou histórico de SMS), que
        usamos para ações como ajudar o USUÁRIO e outras pessoas a encontrar
        pessoas que talvez conheçam e para as outras finalidades listadas
        abaixo.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Seu uso: Coletamos informações sobre como o USUÁRIO usa nossos Serviços,
        como o tipo de conteúdo que o USUÁRIO visualiza ou com o qual se
        envolve; os recursos que o USUÁRIO usa; as ações que o USUÁRIO realiza;
        as pessoas ou contas com que o USUÁRIO interage; e o tempo, frequência e
        duração das suas atividades. Por exemplo, registramos quando o USUÁRIO
        está usando e a última vez que usou nossos Serviços, quais publicações,
        vídeos e outro conteúdo que o USUÁRIO visualizou nos nossos Serviços.
        Nós também coletamos informações sobre como o USUÁRIO usa recursos como
        nossa câmera.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        O que os outros fazem e informações que eles fornecem sobre USUÁRIO:
        Também recebemos e analisamos conteúdo, comunicações e informações que
        outras pessoas fornecem quando usam nossos Serviços. Isso pode incluir
        informações sobre o USUÁRIO, como quando outras pessoas interagem com
        ele por meio da PLATAFORMA.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        10. INFORMAÇÕES DE DISPOSITIVO
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Conforme descrito abaixo, coletamos informações de e sobre os
        computadores, telefones, TVs conectadas e outros dispositivos conectados
        à web que o USUÁRIO usa e que se integram a nossos Serviços, e
        combinamos essas informações dos diferentes dispositivos que o USUÁRIO
        usa. Por exemplo, usamos as informações coletadas sobre seu uso de
        nossos Serviços em seu telefone para personalizar melhor o conteúdo ou
        os recursos que o USUÁRIO vê quando usa nossos Serviços em outro
        dispositivo, como seu laptop ou tablet, ou para avaliar se o USUÁRIO, em
        resposta a um anúncio que exibimos em seu telefone, realizou uma ação em
        um dispositivo diferente.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        11. INFORMAÇÕES DE PARCEIROS
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Os anunciantes, desenvolvedores de aplicativos e publishers podem enviar
        a SOCIALME informações por meio das Ferramentas de Negócios que eles
        usam, inclusive nossos plugins sociais, o Login, nossas APIs e SDKs.
        Esses parceiros fornecem informações sobre atividades do USUÁRIO fora da
        SOCIALME, inclusive informações sobre seu dispositivo, os sites que
        acessa, as compras que faz, os anúncios que visualiza e sobre o uso que
        faz dos serviços deles, independentemente de ter ou não uma conta ou de
        estar conectado ao SOCIALME.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Tais parceiros recebem seus dados quando o USUÁRIO acessa ou usa os
        serviços deles ou por meio de terceiros com os quais eles trabalham.
        Exigimos que cada um desses parceiros tenha autorização legal para
        coletar, usar e compartilhar seus dados antes de fornecê-los para nós.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        12. COMO USAMOS ESSAS INFORMAÇÕES?
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Usamos as informações que temos (em consonância com as escolhas feitas
        pelo USUÁRIO) conforme descrito abaixo e para fornecer e viabilizar a
        operação da SOCIALME.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Usamos as informações que temos para oferecer nossos Serviços, inclusive
        para personalizar recursos e conteúdo e fazer sugestões ao USUÁRIO (como
        grupos ou eventos pelos quais o USUÁRIO possa se interessar ou tópicos
        que o USUÁRIO talvez queira seguir) dentro e fora de nossos Serviços.
        Para criar Serviços personalizados que sejam únicos e relevantes para o
        USUÁRIO, usamos suas conexões, preferências, atividades e seus
        interesses com base nos dados que coletamos e dos quais tomamos
        conhecimento por seu intermédio e de outras pessoas; como o USUÁRIO usa
        e interage com nossos Serviços; as pessoas, as coisas ou os lugares com
        os quais o USUÁRIO esteja conectado e nos quais tenha interesse, dentro
        e fora dos nossos Serviços.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Informações nos Serviços da SOCIALME e dispositivos: Conectamos
        informações sobre suas atividades nos diferentes Serviços da SOCIALME e
        dispositivos para fornecer uma experiência mais personalizada e
        consistente em todos os Serviços que o USUÁRIO usa, onde quer que sejam
        utilizados. Também podemos tornar a sua experiência mais integrada, por
        exemplo, preenchendo automaticamente suas informações de registro (como
        seu número de telefone) a partir de um Produto do SOCIALME quando o
        USUÁRIO se cadastrar para criar uma conta em um Produto diferente.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Informações relacionadas à localização: Utilizaremos informações
        relacionadas à localização, como sua localização atual, histórico, a fim
        de aprimorarmos a PLATAFORMA.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Pesquisa e desenvolvimento de serviços: Usamos as informações que temos
        para desenvolver, testar e aprimorar nossos Serviços, inclusive
        realizando enquetes e pesquisas, e testando e resolvendo problemas
        relativos a novos serviços e recursos.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Anúncios e outros conteúdos patrocinados: Usamos as informações que
        temos sobre o USUÁRIO, inclusive informações sobre seus interesses,
        ações e conexões, para selecionar e personalizar anúncios, ofertas e
        outros conteúdos patrocinados que exibimos para o USUÁRIO.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        13. FORNECER MENSURAÇÃO, ANÁLISES E OUTROS SERVIÇOS COMERCIAIS.
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Usamos as informações que temos para ajudar os anunciantes e outros
        parceiros a avaliar a eficácia e a distribuição dos respectivos anúncios
        e serviços, e também para entender os tipos de pessoas que usam esses
        serviços e como elas interagem com os sites, aplicativos e serviços
        deles.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        14. PROMOVER SEGURANÇA E INTEGRIDADE.
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Usamos as informações que temos para verificar contas e atividades,
        combater condutas danosas, detectar e prevenir spam e outras
        experiências negativas, manter a integridade de nossos Serviços e
        promover a segurança dentro e fora dos Serviços do SOCIALME.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        15. COMUNICAR COM USUÁRIO.
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Usamos as informações que temos para enviar ao USUÁRIO comunicações de
        marketing, para nos comunicar com o USUÁRIO sobre nossos Serviços e para
        informar o USUÁRIO sobre nossas políticas e termos. Também usamos suas
        informações para responder quando o USUÁRIO entra em contato conosco.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        16. PESQUISAR E INOVAR PARA O BEM SOCIAL.
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Usamos as informações que temos (inclusive dos parceiros de pesquisa com
        os quais colaboramos) para realizar e apoiar pesquisas e inovação sobre
        tópicos relacionados ao bem-estar social geral, avanço tecnológico,
        interesse público, saúde e bem-estar.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        17. SUAS INFORMAÇÕES SÃO COMPARTILHADAS COM OUTRAS PESSOAS DAS SEGUINTES
        MANEIRAS.
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Pessoas e contas com quem o USUÁRIO compartilha e se comunica: Quando o
        USUÁRIO compartilha e se comunica usando nossos Serviços, o USUÁRIO
        escolhe o público para aquilo que compartilha.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Informações públicas podem ser vistas por qualquer pessoa, dentro e fora
        de nossos Serviços, inclusive se essa pessoa não tiver uma conta.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        18. CONTEÚDO QUE OUTRAS PESSOAS COMPARTILHAM OU COMPARTILHAM NOVAMENTE
        SOBRE USUÁRIO.
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        O USUÁRIO deve ponderar com quem escolhe compartilhar, porque as pessoas
        que podem visualizar suas atividades em nossos Serviços podem decidir
        compartilhá-las com terceiros dentro e fora de nossos Serviços,
        inclusive com pessoas e empresas fora do público com o qual o USUÁRIO
        compartilhou.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        19. INFORMAÇÕES SOBRE SEU STATUS ATIVO OU PRESENÇA EM NOSSOS SERVIÇOS.
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        As pessoas em suas redes podem ver sinais informando se o USUÁRIO está
        ativo em nossos Serviços, ou quando o USUÁRIO usou pela última vez
        nossos Serviços.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        20. APLICATIVOS, SITES E INTEGRAÇÕES DE TERCEIROS EM NOSSOS SERVIÇOS OU
        QUE USAM NOSSOS SERVIÇOS.
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Quando o USUÁRIO decide usar aplicativos, sites ou outros serviços de
        terceiros que usam ou estão integrados a nossos Serviços, eles podem
        receber informações sobre o que o USUÁRIO pública ou compartilha. As
        informações coletadas por esses serviços de terceiros estão sujeitas aos
        termos e políticas próprias, e não a esta Política.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        21. COMPARTILHAMENTO COM PARCEIROS EXTERNOS
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Trabalhamos com parceiros externos que nos ajudam a fornecer e a
        aprimorar nossos Serviços para ampliar os negócios, o que possibilita a
        operação de nossas empresas e o fornecimento de serviços gratuitos para
        pessoas do mundo inteiro. Não vendemos nenhuma de suas informações para
        ninguém e jamais o faremos. Também impomos fortes restrições sobre como
        nossos parceiros podem usar e divulgar os dados que fornecemos.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        22. PARCEIROS QUE USAM NOSSOS SERVIÇOS DE ANÁLISE
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Fornecemos estatísticas agregadas e insights que ajudam pessoas e
        empresas a entender como os usuários estão se envolvendo com as
        publicações, classificados, páginas, vídeos e outros conteúdos delas
        dentro e fora dos Serviços do SOCIALME.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        23. ANUNCIANTES
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Fornecemos aos anunciantes relatórios sobre os tipos de pessoas que
        visualizam os anúncios deles e sobre o desempenho de tais anúncios, mas
        não compartilhamos informações que identifiquem o USUÁRIO pessoalmente
        (informações como seu nome ou endereço de e-mail, que possam ser usadas
        por si só para contatar ou identificar o USUÁRIO), a menos que o USUÁRIO
        nos dê permissão para tanto.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        24. PARCEIROS DE MENSURAÇÃO
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Compartilhamos informações sobre o USUÁRIO com empresas que as agregam
        para fornecer análises e relatórios de mensuração a nossos parceiros.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        25. PARCEIROS QUE OFERECEM BENS E SERVIÇOS EM NOSSOS SERVIÇOS
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Quando o USUÁRIO compra algo de um vendedor em nossos Serviços, o
        executor do serviço ou vendedor pode receber suas informações públicas e
        outras informações que o USUÁRIO compartilhar com ele, bem como
        informações necessárias para concluir a transação, como detalhes de
        envio e contato.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        26. FORNECEDORES E PROVEDORES DE SERVIÇOS
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Fornecemos informações e conteúdo para fornecedores e provedores de
        serviços que viabilizam a operação de nosso negócio, seja fornecendo
        serviços de infraestrutura técnica, analisando como nossos Serviços são
        usados, oferecendo atendimento ao cliente, facilitando pagamentos ou
        realizando pesquisas.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        27. APLICAÇÃO DA LEI OU SOLICITAÇÕES LEGAIS
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Compartilhamos informações com autoridades responsáveis pela aplicação
        da lei ou em resposta a solicitações legais nas circunstâncias descritas
        abaixo.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        28. COMO FAÇO PARA CONTROLAR OU EXCLUIR INFORMAÇÕES SOBRE MIM?
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Concedemos ao USUÁRIO a capacidade de acessar, retificar, portar e
        apagar seus dados.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Armazenamos dados até que eles não sejam mais necessários para
        fornecermos os serviços do SOCIALME, ou até que sua conta seja excluída,
        o que ocorrer primeiro. Esta é uma determinação que varia de acordo com
        cada caso e depende de fatores como a natureza dos dados, o motivo pelo
        qual são coletados e processados, e necessidades de retenção operacional
        ou legal relevantes.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Quando o USUÁRIO exclui sua conta, nós excluímos o conteúdo que o
        USUÁRIO publicou, como fotos e atualizações de status, e não será
        possível recuperar essas informações. As informações que outras pessoas
        compartilharam sobre o USUÁRIO não fazem parte de sua conta e não serão
        excluídas. Caso o USUÁRIO não deseje excluir sua conta, mas queira parar
        de usar os Serviços temporariamente, é possível desativar a conta.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        29. ACESSAMOS, PRESERVAMOS E COMPARTILHAMOS SUAS INFORMAÇÕES COM
        REGULADORES, AUTORIDADES OU OUTROS
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Compartilhamos informações do USUÁRIO em resposta a uma ordem judicial
        (como um mandado de busca ou intimação), se nós acreditarmos de boa-fé
        que a lei exige que assim façamos. Isso pode incluir a resposta a
        solicitações legais de jurisdições fora do Brasil quando acreditarmos de
        boa-fé que a resposta é exigida por lei na jurisdição em questão, afeta
        usuários na jurisdição em questão e é consistente com padrões
        reconhecidos internacionalmente.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Quando acreditamos de boa-fé que elas são necessárias para: detectar,
        prevenir e resolver questões de fraude, uso não autorizado dos Serviços,
        violações dos nossos termos ou políticas ou outra atividade ilegal ou
        prejudicial; para nos proteger (e proteger nossos direitos, propriedades
        ou Serviços), ao USUÁRIO ou a outras pessoas, inclusive como parte de
        investigações ou inquéritos regulatórios, ou para evitar morte ou danos
        corporais iminentes.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        As informações que recebemos sobre o USUÁRIO podem ser acessadas e
        preservadas por um período maior quando forem objeto de uma requisição
        ou obrigação legal, investigação governamental, investigações de
        possíveis violações de nossos termos ou políticas, ou para de outra
        forma impedir danos. Também retemos informações de contas desabilitadas
        por violação de nossos termos por, no mínimo, um ano, a fim de prevenir
        repetição de abuso ou outras violações dos termos.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        30. COMO OPERAMOS E TRANSFERIMOS DADOS COMO PARTE DE NOSSOS SERVIÇOS
        GLOBAIS?
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Compartilhamos informações globalmente, tanto internamente nos serviços
        do SOCIALME, quanto externamente com nossos parceiros e com aqueles com
        quem o USUÁRIO se conecta e compartilha no mundo todo em conformidade
        com esta política. Suas informações podem, por exemplo, ser transferidas
        ou transmitidas para, ou armazenadas e processadas no Brasil ou outros
        países fora de onde o USUÁRIO mora, para os fins descritos nesta
        política. Essas transferências de dados são necessárias para fornecer os
        serviços estipulados nos Termos de toda a SOCIALME, bem como para operar
        globalmente e fornecer nossos Serviços ao USUÁRIO.
      </Text>
      <br />

      <Title level={4} style={{ fontSize: '12pt' }}>
        31. ALTERAÇÕES DESTA POLÍTICA DE PRIVACIDADE.
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        Se o SOCIALME fizer alterações significativas nesta política, o USUÁRIO
        será notificado por aqui, por e-mail, ou por um aviso na PLATAFORMA
        antes de a alteração entrar em vigor.
      </Text>
      <br />
    </div>
  );
}
