import React, { useContext } from 'react';
//Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
//Components
import {
  WalletOutlined,
  GiftOutlined,
  HeartOutlined,
  UserOutlined,
  ShopOutlined,
  //BankOutlined,
} from '@ant-design/icons';
//Components
import Button from '~/components/button';
// Standalone Components
import { Container, Link } from './style';
// Environments
import System from '~/environments/System';
// Utils
import { UserKind } from '~/utils/UserInformation';

export default function Menu() {
  const { translate } = useContext(LanguageContext);

  const menuItemsPF = [
    {
      icon: <UserOutlined />,
      text: translate.brands.accountManagement.text,
      url: `https://${System.hostname.accountManagement}`,
    },
    {
      icon: <GiftOutlined />,
      text: translate.brands.beneficiary.text,
      url: `https://${System.hostname.beneficiary}`,
    },
    {
      icon: <HeartOutlined />,
      text: translate.brands.donor.text,
      url: `https://${System.hostname.donor}`,
    },
    {
      icon: <WalletOutlined />,
      text: translate.brands.digitalWallet.text,
      url: `https://${System.hostname.digitalWallet}`,
    },
  ];

  const menuItemsPJ = [
    {
      icon: <UserOutlined />,
      text: translate.brands.accountManagement.text,
      url: `https://${System.hostname.accountManagement}`,
    },
    {
      icon: <GiftOutlined />,
      text: translate.brands.beneficiary.text,
      url: `https://${System.hostname.beneficiary}`,
    },
    {
      icon: <HeartOutlined />,
      text: translate.brands.donor.text,
      url: `https://${System.hostname.donor}`,
    },
    {
      icon: <ShopOutlined />,
      text: translate.brands.partner.text,
      url: `https://${System.hostname.partner}`,
    },
    /*
    {
      icon: <BankOutlined />,
      text: translate.brands.manager.text,
      url: `https://${System.hostname.manager}`,
    },
         */
    {
      icon: <WalletOutlined />,
      text: translate.brands.digitalWallet.text,
      url: `https://${System.hostname.digitalWallet}`,
    },
  ];

  return (
    <Container>
      {UserKind() === 'PF'
        ? menuItemsPF.map((item) => (
            <Link key={item.url} href={item.url}>
              <Button type="link" icon={item.icon}>
                {item.text}
              </Button>
            </Link>
          ))
        : menuItemsPJ.map((item) => (
            <Link key={item.url} href={item.url}>
              <Button type="link" icon={item.icon}>
                {item.text}
              </Button>
            </Link>
          ))}
    </Container>
  );
}
