import React, { useState, useContext, useEffect } from 'react';
//Libraries
import { Form, message, Row, Col } from 'antd';
//Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
//Standalone Components
import { Container, ButtonsContainer } from './style';
// Own Components
import CNPJContainer from './components/CNPJContainer';
import GeneralTerms from './components/GeneralTerms';
//Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Button from '~/components/button';
import Title from '~/components/typography/title';
//Environments
import Debug from '~/environments/Debug';

const isDebug = Debug.Modules.Authentication;

export default function RegisterLegalPerson({ login, setStep, setPerson }) {
  const { translate } = useContext(LanguageContext);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    if (!formValues.name || formValues.name.length < 5)
      return setDisableButton(true);
    if (!formValues.social_name || formValues.social_name.length < 5)
      return setDisableButton(true);
    if (!formValues.main_contact || formValues.main_contact.length < 5)
      return setDisableButton(true);
    if (!formValues.auth_password || formValues.auth_password.length < 5)
      return setDisableButton(true);
    if (!formValues.confirm || formValues.confirm.length < 5)
      return setDisableButton(true);
    if (!formValues.agreement_terms) return setDisableButton(true);
    return setDisableButton(false);
  }, [formValues]);

  async function Register(data) {
    setLoadingButton(true);
    if (isDebug) console.log('🚀 API: Auth Register');
    const params = {
      operation: ['Auth', 'Register', 'Legal'],
      data,
    };
    if (isDebug) console.log('📦 Params: ', params);
    const response = await SocialMeAPI(params);
    if (isDebug) console.log('  ⚡ API Response, Auth Register: ', response);
    if (response.success) {
      if (response.message === 'Successfully registered person') {
        if (isDebug) console.log('    Successfully registered person');
        setPerson(response.body.newPerson);
        message.success(translate.authentication.registrationSuccessful.text);
        setLoadingButton(false);
        return setStep('Login');
      }
    } else {
      if (response.message === 'CNPJ in use') {
        if (isDebug) console.log('    CNPJ in use');
        message.error(translate.authentication.theCNPJIsAlreadyRegistered.text);
        return setLoadingButton(false);
      }
      if (response.message === 'Incorrect parameter sent') {
        if (isDebug) console.log('    Incorrect parameter sent');
        message.error(translate.fields.invalid.fields.text);
        return setLoadingButton(false);
      }
    }
  }
  async function onFinish(data) {
    data.kind = 'PJ';
    data.cnpj_number = login.replace(/[^\d]+/g, '');
    Register(data);
  }

  return (
    <Container>
      <Row>
        <Col span={24}>
          <Title
            style={{ margin: '0px auto 10px', textAlign: 'center' }}
            level={4}
          >
            {translate.authentication.legalPersonRegister.text}
          </Title>
        </Col>
      </Row>
      <Form
        labelCol={{
          xs: { span: 24 },
          sm: { span: 24 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 24 },
        }}
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onValuesChange={() => setFormValues(form.getFieldsValue())}
        scrollToFirstError
      >
        <Row>
          <Col span={24}>
            <CNPJContainer login={login} setStep={setStep} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem
              style={{ margin: '0px 0px 10px' }}
              name="name"
              rules={[
                {
                  required: true,
                  message: translate.fields.required.companyName.text,
                },
              ]}
              item={
                <Input
                  label={translate.fields.label.companyName.text}
                  placeholder={translate.fields.example.companyName.text}
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem
              style={{ margin: '0px 0px 10px' }}
              name={['social_name']}
              rules={[
                {
                  required: true,
                  message: translate.fields.required.fantasyName.text,
                },
              ]}
              item={
                <Input
                  label={translate.fields.label.fantasyName.text}
                  placeholder={translate.fields.example.fantasyName.text}
                />
              }
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <FormItem
              style={{ margin: '0px 0px 10px 0px' }}
              name={['main_contact']}
              rules={[
                {
                  required: true,
                  message: translate.fields.required.mainContact.text,
                },
              ]}
              item={
                <Input
                  label={translate.fields.label.mainContact.text}
                  helpText={translate.fields.helpText.mainContact.text}
                  placeholder={translate.fields.placeholder.mainContact.text}
                />
              }
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <FormItem
              style={{ margin: '0px 0px 10px 0px' }}
              name="auth_password"
              item={
                <Input
                  type="password"
                  label={translate.fields.label.password.text}
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem
              style={{ margin: '0px 0px 10px 0px' }}
              name="confirm"
              rules={[
                {
                  required: true,
                  message: translate.fields.required.passwordConfirm.text,
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('auth_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      translate.fields.invalid.passwordConfirm.text
                    );
                  },
                }),
              ]}
              item={
                <Input
                  type="password"
                  label={translate.fields.label.passwordConfirm.text}
                />
              }
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <FormItem
              style={{ margin: '0px 0px 0px 0px', height: 20 }}
              name="agreement_terms"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          translate.authentication.youMustAcceptTheTerms.text
                        ),
                },
              ]}
              item={
                <GeneralTerms
                  form={form}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              }
            />
          </Col>
        </Row>

        <ButtonsContainer>
          <Button
            loading={loadingButton}
            disabled={loadingButton}
            onClick={() => setStep('Verification')}
            size="large"
          >
            {translate.basic.back.text}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingButton}
            disabled={disableButton}
            size="large"
          >
            {translate.basic.next.text}
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  );
}
